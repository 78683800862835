import GetAccredited from './pages/getAccredited';
import CodeOfAdvertising from './pages/codeOfAdvertising';
import Complaints from './pages/complaintsAndReviewsProcessComplaints';
import Hyperlinking from './pages/hyperlinking';
import MissionVision from './pages/missionVision';
import ComplaintAcceptanceGuidelines from './pages/complaintAcceptanceGuidelines';
import ComplaintTermsBaseline from './pages/complaintTermsBaseline';
import OverviewOfRatings from './pages/overviewOfRatings';
import PartnerCodeOfConduct from './pages/partnerCodeOfConduct';
import TermsOfUse from './pages/termsOfUse';
import WhichBbbHandlesYourComplaint from './pages/whichBbbHandlesYourComplaint';
import ApplyForAccreditation from './pages/applyForAccreditation';
import ApplyForAccreditationThankYou from './pages/applyForAccreditationThankYou';
import HotlineComplaint from './pages/hotlineComplaint';
import HotlineComplaintThankYou from './pages/hotlineComplaintThankYou';
import BbbEmailSubscription from './pages/bbbEmailSubscription';

const routes = [
  {
    path: '/get-accredited',
    id: 'getaccredited',
    component: GetAccredited,
  },
  {
    path: '/code-of-advertising',
    id: 'codeofadvertising',
    component: CodeOfAdvertising,
  },
  {
    path: '/hyperlinking',
    id: 'hyperlinking',
    component: Hyperlinking,
  },
  {
    path: '/mission-and-vision',
    id: 'mission',
    component: MissionVision,
  },
  {
    path: '/overview-of-bbb-ratings',
    id: 'overviewofratingsview',
    component: OverviewOfRatings,
  },
  {
    path: '/partner-code-of-conduct',
    id: 'staticcontent_partnercodeofconduct',
    component: PartnerCodeOfConduct,
  },
  {
    path: '/process-of-complaints-and-reviews/complaints',
    id: 'processOfComplaints',
    component: Complaints,
  },
  {
    path: '/process-of-complaints-and-reviews/complaint-acceptance-guidelines',
    id: 'complaintAcceptanceGuidelines',
    component: ComplaintAcceptanceGuidelines,
  },
  {
    path: '/complaint-submission-terms',
    id: 'complaintTermsBaseline',
    component: ComplaintTermsBaseline,
  },
  {
    path: '/process-of-complaints-and-reviews/which-bbb-handles-your-complaint',
    id: 'whichBbbHandlesYourComplaint',
    component: WhichBbbHandlesYourComplaint,
  },
  {
    path: '/terms-of-use',
    id: 'termsofuse',
    component: TermsOfUse,
  },
  {
    path: '/apply',
    id: 'applyForAccreditation',
    component: ApplyForAccreditation,
  },
  {
    path: '/apply/thank-you',
    id: 'applyForAccreditationThankYou',
    component: ApplyForAccreditationThankYou,
  },
  {
    path: '/hotline',
    id: 'hotlineComplaint',
    component: HotlineComplaint,
  },
  {
    path: '/hotline/thank-you',
    id: 'hotlineComplaintThankYou',
    component: HotlineComplaintThankYou,
  },
  {
    path: '/subscription',
    id: 'bbb_email_subscription',
    component: BbbEmailSubscription,
  },
];

export default routes;
