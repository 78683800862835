import { CacheProvider } from '@emotion/react';
import { get as getInitialState } from '@iabbb/context/Redux/initialStateUtils';
import createEmotionCache from '@iabbb/shared/styles/createEmotionCache';
import { initSentry } from '@iabbb/utils/sentry';
import * as ReactDOMClient from 'react-dom/client';

import App from './App';

const cache = createEmotionCache();

initSentry();

// @ts-expect-error - set by webpack
// biome-ignore lint/correctness/noUndeclaredVariables: webpack will set this
if (__DEV__) {
  window.__DEV__ = true;
}

const root = document.getElementById('root');
const initialState = getInitialState();

function AppWithProviders() {
  return (
    <CacheProvider value={cache}>
      <App
        featureFlagState={window.__FEATURE_FLAG_STATE__}
        initialState={initialState}
        href={window.location.href}
      />
    </CacheProvider>
  );
}

if (root) {
  if (!root.childElementCount) {
    // If markup is not present, we need to create it.
    ReactDOMClient.createRoot(root).render(<AppWithProviders />);
  } else {
    // todo - /file-a-complaint throws hydration error
    // try this? https://gist.github.com/OnurGvnc/31f03f0d5237b78224aa083493fda645
    ReactDOMClient.hydrateRoot(root, <AppWithProviders />);
  }
}
